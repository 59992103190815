import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';
import NextLink from 'next/link';
import * as React from 'react';
import { useContext } from 'react';

import { ThemeContext } from '../../../../pages/_app';

export const DemoMain: React.FunctionComponent = () => {
  const theme = useContext(ThemeContext);

  return (
    <section className="flex flex-col p-5 md:flex-row lg:px-16 lg:py-8">
      <div className="mx-auto my-0 flex w-full flex-col items-center gap-10">
        <div
          className="flex h-full w-full flex-col items-center justify-center gap-8 text-center lg:max-w-3xl lg:gap-10">
          <h3 className="block text-5xl">
            Try the editor
          </h3>

          <p className="w-full text-2xl">
            You can try the editor even if the format you need is not supported
            yet. Take a look at what functionality we have. If you like it, you
            can support us so we could add a game you want.
          </p>

          <div>
            <NextLink href="/demo" passHref>
              <a
                role="button"
                className="link-textcolor btn btn-accent"
                target="_blank"
              >
                Show Demo
                <ArrowTopRightOnSquareIcon className="h-6 w-6"/>
              </a>
            </NextLink>
          </div>
        </div>

        <div className="flex h-auto w-full max-w-7xl justify-center">
          <div className="mockup-browser border border-gray-800 bg-base-300">
            <div className="mockup-browser-toolbar">
              <div className="input">https://saveeditor.online/demo</div>
            </div>
            <img
              src={`/${theme}Screenshot.webp`}
              alt="Editor Demo"
            />
          </div>
        </div>
      </div>
    </section>
  );
};
