import { DemoMain, DescriptionMain, FAQMain } from 'components/Pages/Main';
import { UploadDropzone } from 'components/Upload';
import { LayoutMain } from 'layouts/Main';
import { useRouter } from 'next/router';
import * as React from 'react';

const HomePage: React.FunctionComponent = () => {
  const router = useRouter();

  React.useEffect(() => {
    router.prefetch('/editor');
  }, []);

  return (
    <LayoutMain>
      <UploadDropzone
        options={{
          noClick: true,
          noKeyboard: true,
        }}
      >
        <DescriptionMain/>
        <DemoMain/>
        <FAQMain/>
      </UploadDropzone>
    </LayoutMain>
  );
};

export default HomePage;
