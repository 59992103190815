import { ACCEPTED_FORMATS, UploadButton } from 'components/Upload';
import * as React from 'react';

import { Donation } from './Donation';
import { FormatsMain } from './Formats';

export const DescriptionMain: React.FunctionComponent = () => {
  return (
    <section className="mx-auto flex min-h-[95vh] flex-col justify-between gap-6 p-5 lg:flex-row lg:px-16 lg:py-8">
      <div className="flex h-full w-full flex-col gap-8 lg:w-1/2">
        <h1 className="block text-6xl font-light">Save Editor Online</h1>


        <p className="block text-2xl">
          The ultimate destination for gamers looking to enhance their gaming
          experience, easily edit your save files for a variety of games, change
          money, gold, level, progress, and more. Take control of your gaming
          experience and start editing your save files today.
        </p>

        <Donation/>
        <FormatsMain/>
      </div>

      <div className="flex w-full flex-col items-center justify-center lg:w-1/2">
        <div
          className="flex h-full w-full flex-col items-center justify-center gap-3 rounded-2xl bg-dropzone px-5 py-24"
        >

          <p className="text-2xl">
            {'Drag \'n\' drop save file'}
          </p>

          <span className="divider">or</span>

          <UploadButton/>

          <p>
            {ACCEPTED_FORMATS.join(' | ')}
          </p>
        </div>
      </div>
    </section>
  );
};
