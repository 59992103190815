import { ArrowPathIcon, CheckIcon } from '@heroicons/react/24/outline';
import { map } from 'lodash';
import * as React from 'react';

type FormatsType = Array<{
  name: string;
  extension: string;
  inProgress?: boolean;
}>;

const FORMATS: FormatsType = [
  {
    extension: '.save',
    name: 'Ren\'Py',
  },
  {
    extension: '.rpgsave',
    name: 'RPGMaker MV',
  },
  {
    extension: '.rmmzsave',
    name: 'RPGMaker MZ',
  },
  {
    extension: '.rxdata',
    name: 'RPG Maker XP',
  },
  {
    extension: '.rvdata',
    name: 'RPG Maker VX',
  },
  {
    extension: '.rvdata2',
    name: 'RPG Maker VX Ace',
  },
  {
    extension: '.nson',
    name: 'NaniNovel',
  },
];

export const FormatsMain: React.FunctionComponent = () => {
  return (
    <section className="flex flex-col items-start gap-6 p-5 lg:items-center lg:p-8">
      <h2 className="block self-center text-2xl">
        Supported Game Engines
      </h2>

      <ul className="grid w-full grid-cols-1 gap-2 sm:grid-cols-3 lg:grid-cols-2 xl:grid-cols-3">
        {map(FORMATS, (format) => {
          return (
            <li
              key={format.extension}
              className="flex w-full items-center justify-start gap-6 px-4 py-1"
            >
              <div>
                {format.inProgress ? (
                  <ArrowPathIcon className="h-6 w-6 text-accent"/>
                ) : (
                  <CheckIcon className="h-6 w-6 text-accent"/>
                )}
              </div>
              <div>
                <span>{format.name}</span>
                <p className="text-gray-500">{format.extension}</p>
              </div>
            </li>
          );
        })}
      </ul>
    </section>
  );
};
