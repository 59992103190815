import { HeartIcon } from '@heroicons/react/24/solid';
import * as ga from 'lib/ga';
import * as React from 'react';

import { BoostyIcon } from '../../Icons/BoostyIcon';
import { PatreonIcon } from '../../Icons/PatreonIcon';

export const Donation: React.FunctionComponent = () => {
  return (
    <div className="box-border w-full rounded-2xl bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 p-1">
      <div
        className="relative box-border flex h-full w-full flex-col gap-4 rounded-2xl bg-base-100 pb-6 pl-4 pr-10 pt-4">
        <h2 className="text-2xl">
          Your support = New Formats, Design and Features!
        </h2>
        <p>
          {`Like Save Editor? Support us and help shape its future! With your
          generous contributions, we can continue enhancing our platform and
          work on adding the games YOU want. By becoming a patron, you'll be a
          crucial part of our development process, unlocking new possibilities
          for customizing your gaming experience. Don't miss this opportunity -
          join our community today and make your voice heard!`}
        </p>

        <HeartIcon className="absolute right-3 top-4 h-6 w-6 text-secondary"/>

        <div className="flex flex-col flex-wrap items-center justify-start gap-4 md:flex-row">
          <a
            href="https://www.patreon.com/SaveEditorOnline"
            target="_blank"
            className="btn btn-primary"
            title="Support on Patreon" rel="noreferrer"
            onClick={() => {
              ga.sendEvent({
                action: 'patreon_click',
                label: 'homepage_donation',
              });
            }}
          >
            <PatreonIcon/>
            Support on Patreon
          </a>
          <span className="divider lg:divider-horizontal">or</span>
          <a
            href="https://boosty.to/saveeditor"
            target="_blank"
            className="btn btn-secondary"
            title="Support on Boosty" rel="noreferrer"
            onClick={() => {
              ga.sendEvent({
                action: 'boosty_click',
                label: 'homepage_donation',
              });
            }}
          >
            <BoostyIcon/>
            Support on Boosty
          </a>
        </div>
      </div>
    </div>
  );
};
