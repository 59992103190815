import * as React from 'react';

type MainPageFAQItemProps = {
  title: string;
};

export const MainPageFAQItem: React.FunctionComponent<
  React.PropsWithChildren<MainPageFAQItemProps>
> = (props) => {
  const { title, children } = props;
  return (
      <div tabIndex={0} className="collapse collapse-open bg-base-200">
        <div className="collapse-title text-xl font-medium text-accent">
          {title}
        </div>
        <div className="collapse-content flex flex-col gap-4">
          {children}
        </div>
      </div>
  );
};
