import {CheckIcon} from "@heroicons/react/24/outline";
import * as React from 'react';

import { MainPageFAQItem } from './Item';

const FAQ = [
  {
    content: (
      <>
        <p>
          {`A save editor is a software tool that allows you to modify every
          aspect of your game save files without losing any data. The online
          save editor is the ultimate tool for customizing your favorite games.
          With support for popular formats like Ren'Py, RPG Maker MV/MZ/VX/XP,
          NaniNovel, and JSON, our editor is compatible with a wide range of
          games. Save Editor ensures that you never lose any data and makes it
          easy to make changes to your game saves.`}
        </p>
        <p>
          {`With a powerful search feature, you can quickly find the values you
          need to customize. SaveEditor.online gives you complete control over
          your gaming experience and unlocks new possibilities. Try it out today
          and start taking your gaming to the next level!`}
        </p>
      </>
    ),
    title: 'What is a save editor, and how does it work?',
  },
  {
    content: (
      <>
        <p>
          {`The save editor prioritizes safety and security and is designed to
          make modifying your game saves as safe as possible. However, it's
          always a good idea to make a backup of your original save file before
          using the editor, just in case something goes wrong. This will ensure
          that you can revert to your original save file if any issues arise.`}
        </p>
        <p>
          {`Our editor is designed to be non-destructive, meaning that it won't
          affect your original game or save files. Instead, our tool creates a
          copy of the original save file and makes changes to that copy. This
          way, you can make changes to your game saves without the risk of data
          loss or corruption.`}
        </p>
        <p>
          {`While using our save editor, you should also be careful not to modify
          any fields that you're unsure about. Some game saves have complex
          structures and interdependencies, and modifying the wrong field could
          cause issues with your game. However, with Save Editor's user-friendly
          interface and powerful search feature, you can quickly and easily find
          and modify the fields you need to customize.`}
        </p>
        <p>
          {`In summary, while SaveEditor.online is designed to be safe and secure,
          it's always a good idea to make a backup of your original save file
          before using the editor. With our non-destructive editing and powerful
          search features, you can safely and easily customize your game saves
          to your liking.`}
        </p>
      </>
    ),
    title:
      'Is it safe to use the save editor? Will it affect my game or save files?',
  },
  {
    content: (
      <p>
        {`You can edit anything from in-game resources like money, level, and
        progress to various game settings. Our tool allows you to view and
        change all the information you need without removing any data. However,
        we recommend creating a backup before making any changes to avoid losing
        progress. Try it out today and take control of your gaming experience!`}
      </p>
    ),
    title: 'What can I change in my save file?',
  },
  {
    content: (
      <>
        <p>
          {`Our search feature is designed to be both powerful and versatile, providing you with the ability to efficiently locate any value or variable in your save file. With multiple search modes and filters, you can easily find the information you need to customize your gaming experience. Here's an overview of what our search function offers:`}
        </p>

        <ul className="flex flex-col items-start gap-2 lg:px-8 lg:py-4">
            <li className="flex w-full items-center justify-start gap-7 px-4 py-2">
                <div>
                    <CheckIcon className="h-6 w-6 text-accent"/>
                </div>
                <span>Search for specific values, ranges of values, strings, or strings containing specific values.</span>
            </li>
            <li className="flex w-full items-center justify-start gap-7 px-4 py-2">
                <div>
                    <CheckIcon className="h-6 w-6 text-accent"/>
                </div>
                <span>{`Use partial match mode to find related values and variable names (e.g., searching for "mon" will return results like "Monday," "money," or "diamond").`}</span>
            </li>
            <li className="flex w-full items-center justify-start gap-7 px-4 py-2">
                <div>
                    <CheckIcon className="h-6 w-6 text-accent"/>
                </div>
                <span>Enable full match mode for precise search results, returning only exact matches for your query..</span>
            </li>
            <li className="flex w-full items-center justify-start gap-7 px-4 py-2">
                <div>
                    <CheckIcon className="h-6 w-6 text-accent"/>
                </div>
                <span>Filter your search results based on data types, such as numbers or strings, for more refined results.</span>
            </li>
        </ul>
        <p>
          For a detailed, step-by-step guide on how to make the most of our
          search feature, please refer to{' '}
          <a
            className="link link-primary"
            href="https://www.patreon.com/posts/how-to-use-73969792"
            target="_blank"
            rel="noreferrer"
          >
              our comprehensive instruction
          </a>
          . This guide will help you utilize our search functionality
          effectively and customize your gaming experience like never before.
        </p>

        <p>
          {`In summary, Save Editor Online's search feature is designed to help you quickly and easily find the information you need, allowing you to fully unlock your gaming potential.`}
        </p>
      </>
    ),
    title: 'How does the search work?',
  },
  {
    content: (
      <>
        <p>
          {`We understand that having the ability to undo changes made to your
          game save files is an important feature for a better user experience.
          Currently, our editor does not have a built-in undo feature. However,
          we offer a "Show only changed fields" checkbox that allows you to
          filter and see all of the changes you've made to your save file.`}
        </p>
        <p>
          {`By using this feature, you can observe all of the changes you've made
          and manually reset any fields back to their original values. While
          this may not be as convenient as an undo feature, it can still be a
          helpful way to revert any unwanted changes.`}
        </p>
        <p>
          {`We want to assure you that we are actively working on adding an undo
          feature to our editor in the near future. We are committed to
          providing the best possible user experience, and we understand the
          importance of having this feature for our users. In the meantime, we
          recommend that you use the "Show only changed fields" checkbox to help
          you keep track of your changes and make it easier to manually reset
          any fields if needed.`}
        </p>
      </>
    ),
    title: 'Can I undo changes made with the save editor?',
  },
] as const;

export const FAQMain: React.FunctionComponent = () => {
  return (
    <section id="faq" className="flex flex-col items-center justify-center gap-8 p-8">
      <h3 className="text-5xl">
        FAQ
      </h3>

      <div className="flex max-w-7xl flex-col items-baseline justify-between gap-8">
        {FAQ.map(({ title, content }, index) => (
          <MainPageFAQItem key={index} title={title}>
            {content}
          </MainPageFAQItem>
        ))}
      </div>
    </section>
  );
};
